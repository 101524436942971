@keyframes pulsestates {
  from {
    transform: scale(1);
    opacity: 0.4;
  }

  to {
    transform: scale(1.05);
    opacity: 1;
  }
}

.pulse {
  animation-duration: 1s;
  animation-name: pulsestates;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.17, 0.52, 1, 1);
}
