@font-face {
font-family: '__sf_2bf119';
src: url(/_next/static/media/7fee29fde19b19e3-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__sf_Fallback_2bf119';src: local("Arial");ascent-override: 101.50%;descent-override: 25.71%;line-gap-override: 0.00%;size-adjust: 93.80%
}.__className_2bf119 {font-family: '__sf_2bf119', '__sf_Fallback_2bf119';font-weight: 400
}

@font-face {
font-family: '__coa_683a34';
src: url(/_next/static/media/6bcc0f72ffaa204d-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__coa_Fallback_683a34';src: local("Arial");ascent-override: 96.47%;descent-override: 23.43%;line-gap-override: 0.00%;size-adjust: 93.99%
}.__className_683a34 {font-family: '__coa_683a34', '__coa_Fallback_683a34'
}

@font-face {
font-family: '__cos_95ebac';
src: url(/_next/static/media/e7fd2fef6a617801-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__cos_Fallback_95ebac';src: local("Arial");ascent-override: 99.76%;descent-override: 24.23%;line-gap-override: 0.00%;size-adjust: 90.89%
}.__className_95ebac {font-family: '__cos_95ebac', '__cos_Fallback_95ebac'
}

@font-face {
font-family: '__cotc_9eadf9';
src: url(/_next/static/media/145e76f1a2f906be-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__cotc_Fallback_9eadf9';src: local("Arial");ascent-override: 100.00%;descent-override: 28.00%;line-gap-override: 0.00%;size-adjust: 100.00%
}.__className_9eadf9 {font-family: '__cotc_9eadf9', '__cotc_Fallback_9eadf9'
}

@keyframes pulsestates {
  from {
    transform: scale(1);
    opacity: 0.4;
  }

  to {
    transform: scale(1.05);
    opacity: 1;
  }
}

.pulse {
  animation-duration: 1s;
  animation-name: pulsestates;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.17, 0.52, 1, 1);
}

